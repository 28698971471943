import axios from 'axios'
import Cookies from 'universal-cookie'
import { simpleSignOut } from 'bundles/shared/lib/requests'
import i18n from 'bundles/config/i18n'
import { store } from 'bundles/shared/lib/redux/store'
import { setFlash } from 'bundles/shared/lib/redux/actions'
import i18next from 'i18next'

const AxiosInterceptors = (onUnauthenticated) => {
  const onRequestSuccess = (config) => {
    const cookies = new Cookies()
    const token = cookies.get('oauthToken')
    if (token) {
      config.headers.Authorization = 'Bearer ' + token
    }
    if (config.method.match(/^(post|put|patch|delete)$/i)) {
      config.headers['Content-Type'] = config.headers['Content-Type'] || 'application/json'
    }
    config.headers['Accept-Language'] = i18n.language
    return config
  }

  const onResponseSuccess = (response) => response
  const onResponseError = (err) => {
    const status = err.status || (err.response ? err.response.status : 0)
    switch (status) {
      case 401:
        onUnauthenticated()
        simpleSignOut()
        break
      case 500:
        store.dispatch(setFlash('error', i18next.t('errors.500')))
    }

    return Promise.reject(err)
  }
  axios.interceptors.request.use(onRequestSuccess)
  axios.interceptors.response.use(onResponseSuccess, onResponseError)
}

export default AxiosInterceptors
