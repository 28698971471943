import React from 'react'
import { PropTypes } from 'prop-types'
import { connect } from 'react-redux'
import Input from 'bundles/bu_flex_dashboard/components/form/input'
import { Link } from 'react-router-dom'
import { Checkbox } from 'bundles/shared/components/form/checkbox'
import ExclamationMarkIcon from 'icons/shared/exclamation_mark.svg'
import { setDialogOverlay } from 'bundles/shared/lib/redux/actions'
import ErrorTooltip from 'bundles/bu_flex_dashboard/components/form/error_tooltip'
import { withTranslation } from 'react-i18next'
import InfoDialog from './info_dialog'
import { handleRegistration } from '../../../signed_off/lib/requests'
import { getConsortiums } from '../../lib/requests'
import Select from './select'

const mapStateToProps = (state) => ({
  showPrivacyDialog: state.options.dialogOverlay
})

function mapDispatchToProps(dispatch) {
  return {
    setDialogOverlay: (overlay) => dispatch(setDialogOverlay(overlay))
  }
}

class Register extends React.Component {
  static propTypes = {
    setDialogOverlay: PropTypes.func.isRequired,
    showPrivacyDialog: PropTypes.bool.isRequired,
    t: PropTypes.func.isRequired
  }

  constructor(props) {
    super(props)
    this.state = {
      validationErrors: {},
      consortiums: [],
      loadingConsortiums: true,
      consultant: {
        first_name: '',
        last_name: '',
        email_candidate: '',
        consortium: {
          id: ''
        },
        consultant_number: '',
        password: '',
        password_confirmation: ''
      }
    }
  }

  componentDidMount() {
    getConsortiums(this.onGetConsortiumsSuccess)
  }

  onGetConsortiumsSuccess = (consortiums) => {
    this.setState({ consortiums, loadingConsortiums: false })
  }

  onRegister = (event) => {
    event.preventDefault()
    handleRegistration(this, this.state.consultant, this.onSubmitSuccess, this.onSubmitError)
  }

  onSubmitSuccess = (data) => {
    this.setState({ success_message: data.message })
  }

  onSubmitError = (error) => {
    if (error.mailer_error) {
      this.setState({ mailer_error: error.mailer_error })
    } else {
      this.setState({ error_message: error.message })
    }
  }

  onChange = (value, error, attr) => {
    let validationErrors = this.state.validationErrors
    validationErrors[attr] = error
    let consultant = { ...this.state.consultant, [attr]: value }
    this.setState({ consultant, validationErrors })
  }

  onPrivacyChange = () => {
    this.setState({ privacy: !this.state.privacy })
  }

  onChangeSelect = (value) => {
    let consortium = { ...this.state.consultant.consortium, id: value }
    let consultant = { ...this.state.consultant, consortium }
    this.setState({ consultant })
  }

  renderPrivacyDialog = () => {
    this.props.setDialogOverlay(true)
  }

  handlePrivacyClose = () => {
    this.props.setDialogOverlay(false)
  }

  consortiumTypes() {
    return this.state.consortiums.map((consortium) => {
      if (consortium.name !== 'Bonusassekuranz') {
        return { value: consortium.id, label: consortium.name }
      }
      return { value: consortium.id, label: 'Bonusassekuranz (Makler/Pool)' }
    })
  }

  render() {
    const t = this.props.t
    return (
      <>
        <div className="box register-box">
          <div>
            <Link to="/login">{t('bu:user.login_button')}</Link>
            <Link to="/register" className="active">
              {t('bu:user.register')}
            </Link>
          </div>
          {this.state.success_message ? (
            <div className="register-message">{this.state.success_message}</div>
          ) : (
            <form onSubmit={this.onRegister}>
              <div className="input-info-field">
                <Input
                  labelPrefix="bu:user"
                  validationErrors={this.state.validationErrors}
                  validates={{ charLength: { max: 255 }, presence: {} }}
                  attr="first_name"
                  onChange={this.onChange}
                />
                <ErrorTooltip errorMessage={(this.state.validationErrors.first_name || []).join(' ')} />
              </div>
              <div className="input-info-field">
                <Input
                  labelPrefix="bu:user"
                  validationErrors={this.state.validationErrors}
                  validates={{ charLength: { max: 255 }, presence: {} }}
                  attr="last_name"
                  onChange={this.onChange}
                />
                <ErrorTooltip errorMessage={(this.state.validationErrors.last_name || []).join(' ')} />
              </div>
              <div className="input-info-field">
                <Input
                  labelPrefix="bu:user"
                  validationErrors={this.state.validationErrors}
                  validates={{ charLength: { max: 255 }, presence: {}, validEmail: {} }}
                  attr="email_candidate"
                  onChange={this.onChange}
                  onBlur={this.onChange}
                />
                <ErrorTooltip errorMessage={(this.state.validationErrors.email_candidate || []).join(' ')} />
              </div>
              <div className="input-info-field">
                <label>
                  {t('bu:user.consortium')}
                  <Select
                    label=""
                    onChange={this.onChangeSelect}
                    options={this.consortiumTypes()}
                    validationError={(this.state.validationErrors.consortium || []).join(' ')}
                    placeholder=""
                  />
                </label>
                <div className="info-svg tooltip">
                  <ExclamationMarkIcon />
                  <span className="tooltiptext">
                    Bitte wählen Sie hier Ihre Vertriebsorganisation. Falls Sie keiner der Organisationen angehören,
                    wählen Sie bitte Bonusassekuranz (Makler/Pool).
                  </span>
                </div>
              </div>
              <div className="input-info-field">
                <Input
                  labelPrefix="bu:user"
                  validationErrors={this.state.validationErrors}
                  attr="consultant_number"
                  onChange={this.onChange}>
                  {t('bu:user.consultant_number')}
                  &nbsp;
                  <span className="input-optional">({t('bu:user.input.optional')})</span>:
                </Input>
                <div className="info-svg" />
              </div>
              <div className="input-info-field">
                <Input
                  labelPrefix="bu:user"
                  validationErrors={this.state.validationErrors}
                  attr="password"
                  type="password"
                  onChange={this.onChange}
                />
                <div className="info-svg tooltip">
                  <ExclamationMarkIcon />
                  <span className="tooltiptext">
                    Ihr Passwort muss folgende Kriterien erfüllen:
                    <br />
                    ● mindestens 9 oder mehr Zeichen
                    <br />
                    ● Groß- und Kleinbuchstaben
                    <br />● Sonderzeichen und Ziffern
                  </span>
                </div>
              </div>
              <div className="input-info-field">
                <Input
                  labelPrefix="bu:user"
                  validationErrors={this.state.validationErrors}
                  attr="password_confirmation"
                  type="password"
                  onChange={this.onChange}
                />
                <ErrorTooltip errorMessage={(this.state.validationErrors.password_confirmation || []).join(' ')} />
              </div>
              <div id="privacy">
                {this.props.showPrivacyDialog && (
                  <InfoDialog
                    handleCancel={this.handlePrivacyClose}
                    infoTitle={t('bu:user.privacy_info_title')}
                    infoText={t('bu:user.privacy_info_text')}
                  />
                )}
                <Checkbox
                  labelFor="reg-privacy"
                  name="privacy"
                  handleChange={this.onPrivacyChange}
                  checked={this.state.privacy}
                />
                <div className="privacy-text">
                  {t('bu:user.privacy_text_1')}
                  &nbsp;
                  <a onClick={this.renderPrivacyDialog}>{t('bu:user.privacy_link_text')}</a>
                  &nbsp;
                  {t('bu:user.privacy_text_2')}
                </div>
              </div>
              <input
                type="submit"
                disabled={this.state.disabled || !this.state.privacy}
                value={t('bu:user.register')}
                className={`btn ${this.state.disabled || !this.state.privacy ? 'disabled' : ''}`}
              />
            </form>
          )}
        </div>
      </>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Register))
