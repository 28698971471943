import validators from './validators'

const ValidatorMapping = {
  confirmation: validators.ConfirmationValidator,
  charLength: validators.CharLengthValidator,
  presence: validators.PresenceValidator,
  validEmail: validators.ValidEmailValidator
}

class Validator {
  constructor(options) {
    this.options = options || {}
  }

  validate = (value) => {
    let validationErrors = []
    Object.keys(this.options).forEach((validatorName) => {
      let options = this.options[validatorName]
      if (options === true) {
        options = {}
      } else if (typeof options === 'string') {
        options = { message: options }
      }
      const validator = new ValidatorMapping[validatorName](value, options)
      const message = validator.errorMessage()
      if (message) {
        validationErrors.push(message)
      }
    })
    return validationErrors
  }
}

export default Validator
