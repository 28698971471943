import 'react-app-polyfill/ie11';
import 'core-js/features/object';
import ReactOnRails from 'react-on-rails';
import { BUFlex } from '../bundles/bu_flex/components/bu_flex';
import { ServerError } from "../bundles/shared/components/server_error";
import { PageNotFound } from "../bundles/shared/components/page_not_found";
import { BrowserNotSupported } from "../bundles/shared/components/browser_not_supported";
import * as Sentry from '@sentry/browser';

if (process.env.NODE_ENV !== "development") {
  Sentry.init(
    {
      dsn: "https://e4c75336016345c98d9dd130b044c2b0@sentry.monitoring.xboard.x-ion.org/9",
      environment: window.location.hostname
    });
  Sentry.setTag("bundle", "bu-flex");
}
ReactOnRails.register({
  BUFlex,
  ServerError,
  PageNotFound,
  BrowserNotSupported
});
