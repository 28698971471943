import React from 'react'
import PropTypes from 'prop-types'
import ErrorIcon from 'icons/bu/error_icon.svg'
import { connect } from 'react-redux'
import { confirmCookieConsent } from 'bundles/shared/lib/redux/actions'
import { withTranslation } from 'react-i18next'

function mapDispatchToProps(dispatch) {
  return {
    confirmCookieConsent: () => dispatch(confirmCookieConsent())
  }
}

const CookieBanner = ({ ...props }) => (
  <div id="cookie-banner">
    <div>
      <ErrorIcon className="svg-icon" />
    </div>
    <div>
      <p className="cookie-title">Auf dieser Seite werden Cookies eingesetzt</p>
      <p>
        Indem Sie weiter auf dieser Website navigieren, ohne die Cookie-Einstellungen Ihres Internet-Browsers zu ändern,
        stimmen Sie unserer Verwendung von Cookies zu. Weitere Hinweise zu Cookies finden Sie in unseren&nbsp;
        <span>
          <a href={props.privacyPolicy}>{props.t('cookie_text.data_policy_button')}</a>
        </span>
        .
      </p>
    </div>
    <a href="#" className="btn" onClick={props.confirmCookieConsent}>
      OK
    </a>
  </div>
)

CookieBanner.propTypes = {
  confirmCookieConsent: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  privacyPolicy: PropTypes.string.isRequired
}

export default connect(null, mapDispatchToProps)(withTranslation()(CookieBanner))
