import React from 'react'
import { PropTypes } from 'prop-types'
import BuisnessHorizontalIcon from 'icons/bu/buisness_horizontal.svg'
import { withTranslation } from 'react-i18next'

const WelcomeText = ({ t }) => (
  <div id="welcome-text">
    <p className="title">{t('bu:mainpage.welcome.title_1')}</p>
    <p className="title">{t('bu:mainpage.welcome.title_2')}</p>
    <p className="sub-title">{t('bu:mainpage.welcome.sub_title')}</p>
    <BuisnessHorizontalIcon />
  </div>
)

WelcomeText.propTypes = {
  t: PropTypes.func.isRequired
}

export default withTranslation()(WelcomeText)
