import React from 'react'
import PropTypes from 'prop-types'

export const ServerError = ({ ...props }) => (
  <div id="server-error">
    <div>
      <p>{props.title}</p>
      <p>
        {props.sub_title_1}
        <span>
          {props.sub_title_2} <a href="/">{props.sub_title_3}</a>
        </span>
      </p>
    </div>
  </div>
)

ServerError.propTypes = {
  title: PropTypes.string.isRequired,
  sub_title_1: PropTypes.string.isRequired,
  sub_title_2: PropTypes.string.isRequired,
  sub_title_3: PropTypes.string.isRequired
}
