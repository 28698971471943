import React from 'react'

export const PrivacyPolicyAdmin = () => (
  <div id="privacy-policy">
    <p className="title">Datenschutz</p>
    <div>
      <p className="sub-title">1. Verarbeitung Ihrer personenbezogenen Daten</p>
      <p>
        Hiermit setzen wir Sie in Kenntnis über die Verarbeitung Ihrer personenbezogenen Daten bei Verwendung der
        Administrationsoberfläche AKS Flex gemäß den Bestimmungen der aktuell geltenden Datenschutzvorschriften,
        insbesondere der Datenschutz-Grundverordnung (DSGVO).
      </p>
    </div>

    <div>
      <p className="sub-title">2. Begriffsbestimmungen</p>
      <p>
        Personenbezogene Daten im Sinne des Art. 4 Nr. 1 DSGVO sind sämtliche Informationen, welche sich auf Sie als
        Person beziehen, wie beispielsweise Ihr Vor- und Zuname, Ihre E-Mail Adresse oder Ihre Anschrift.
      </p>
      <p>
        Eine Verarbeitung Ihrer personenbezogenen Daten umfasst jeden Vorgang, der in einem Zusammenhang mit
        personenbezogenen Daten steht, wie beispielsweise das Erheben, Speichern, Löschen oder die Übermittlung der
        Daten gemäß Art. 4 Nr. 2 DSGVO.
      </p>
      <p>
        Verantwortlicher (bzw. Verantwortliche Stelle) für die Datenverarbeitung ist gemäß Art. 4 Nr. 7 DSGVO jede
        natürliche oder juristische Person oder sonstige Institution, die alleine oder gemeinsam mit anderen
        Verantwortlichen über die Zwecke und Mittel der Datenverarbeitung entscheidet.
      </p>
      <p>
        Auftragsverarbeiter nach Art. 4 Nr. 8 DSGVO ist jede natürliche oder juristische Person oder sonstige Stelle,
        die eine Verarbeitung personenbezogener Daten im Auftrag des Verantwortlichen nach Art. 28 DSGVO durchführt.
      </p>
    </div>

    <div>
      <p className="sub-title">3. Verantwortliche Stelle/Datenschutzbeauftragter</p>
      <p>Verantwortliche Stelle für die Verarbeitung Ihrer personenbezogenen Daten gemäß Art. 4 Nr. 7 DSGVO ist:</p>
      <p className="group">IG BCE Bonusagentur GmbH</p>
      <p className="group">Fischerstraße 5</p>
      <p className="group">30167 Hannover</p>
      <p>E-Mail: kontakt@igbce-bonusagentur.de</p>
      <p>Unseren Datenschutzbeauftragten erreichen Sie unter: datenschutz@igbce-bonusagentur.de</p>
    </div>

    <div>
      <p className="sub-title">4. Verarbeitung Ihrer Daten bei Anmeldung</p>
      <p>
        Soweit Sie über eine Berechtigung für die Benutzung der Administrationsoberfläche verfügen, erhalten Sie zum
        Zweck einer erstmaligen Anmeldung eine E-Mail mit Ihren Zugangsdaten (Username, Passwort). Im eingeloggten
        Bereich der Administrationsoberfläche haben Sie die Möglichkeit zur Vervollständigung bzw. Änderung der
        nachfolgenden Daten:
      </p>
      <p className="group indent">- Ihre E-Mail Adresse</p>
      <p className="group indent">- Ihre Telefonnummer (optional)</p>
      <p className="indent">- Ihr Passwort</p>
      <p>
        Die Verarbeitung Ihrer personenbezogenen Daten erfolgt zum Zweck der Freischaltung Ihres Accounts bzw. zur
        Benutzung der Administrationsoberfläche in Ihrer Funktion als Administrator des AKS Flex Portals.
      </p>
      <p>
        Rechtsgrundlage für diese Datenverarbeitung stellt unser berechtigtes Interesse gemäß Art. 6 Abs. 1 S. 1 lit. f
        DSGVO dar. Dieses ergibt sich aus den hier dargelegten Gründen.
      </p>
    </div>

    <div>
      <p className="sub-title">5. Verarbeitung Ihrer Daten bei Nutzung dieser Website </p>
      <p>
        Des Weiteren werden bei Besuch dieser Website die nachfolgenden Informationen in automatisierter Form durch uns
        erfasst:
      </p>
      <p className="group indent">- Ihre IP Adresse</p>
      <p className="group indent">- Zugriffspfad der abgerufenen Datei (URL)</p>
      <p className="group indent">- Datum und Uhrzeit der Anfrage (UTC Zeit)</p>
      <p className="group indent">- Verwendeter Browser inklusive Version/verwendetes Betriebssystem</p>
      <p className="group indent">- Protokoll</p>
      <p className="indent">- HTTP Status Codes</p>
      <p>
        Eine Verarbeitung dieser Informationen bzw. Daten erfolgt zu dem Zweck, einen fehlerfreien Verbindungsaufbau
        dieser Website sowie eine komfortable Nutzung gewährleisten zu können. Rechtsgrundlage dieser Datenverarbeitung
        ergibt sich aus Art. 6 Abs. 1 S. 1 lit. f DSGVO. Unser berechtigtes Interesse an der Datenverarbeitung ergibt
        sich aus den hier dargelegten Zwecken.
      </p>
    </div>

    <div>
      <p className="sub-title">6. Cookies</p>
      <p>
        Während des Besuchs dieser Webseite sowie bei Verwendung der Administrationsoberfläche werden auf Ihrem Endgerät
        Cookies gespeichert. Cookies stellen kleine Textdateien dar, welche auf Ihrem Browser abgelegt und dieser
        Webseite auf zuordenbarer Weise gespeichert werden und zum Zweck der Authentifizierung und Autorisierung des
        jeweiligen Nutzers eingesetzt werden. Durch den Einsatz von Cookies erfolgt die Erstellung eines Session
        Cookies, wodurch die Identifikationsnummer des Nutzers zum Zweck der Authentifizierung erhoben wird. Zudem
        erfolgt eine Speicherung der Information, ob das Cookie durch den jeweiligen Nutzer bestätigt worden ist. Nach
        erfolgreichem Einloggen wird ein oAuth-Token erzeugt, um den jeweiligen Nutzer zu autorisieren und ihm bestimmte
        Berechtigungen zuzuweisen. Die Verarbeitung dieser Daten erfolgt auf der Rechtsgrundlage des Art. 6 Abs. 1 S. 1
        lit. f DSGVO. Unser berechtigtes Interesse an dieser Verarbeitung folgt aus den durch uns hier dargelegten
        Zwecken.
      </p>
    </div>

    <div>
      <p className="sub-title">7. Speicherdauer Ihrer personenbezogenen Daten</p>
      <p>
        Eine Speicherung der durch uns erhobenen personenbezogenen Daten erfolgt zu den nachfolgend aufgeführten
        Zeiträumen:
      </p>
      <p>
        Ihre bei Besuch dieser Website erhobenen, personenbezogenen Daten (siehe Punkt 5) werden für einen Zeitraum von{' '}
        <span>14 Tagen</span> gespeichert und anschließend im Rahmen eines automatisierten Prozesses durch uns gelöscht.
      </p>
      <p>
        Die bei Anmeldung bzw. Nutzung der Administrationsoberfläche erhobenen Daten (siehe Punkt 4) werden für einen
        Zeitraum von <span>einem Jahr nach Beendigung der jeweiligen Nutzungsdauer</span> durch uns gespeichert und
        anschließend gelöscht.
      </p>
      <p>
        Die Information, ob das jeweilige Cookie durch den Nutzer bestätigt wurde, sowie die jeweilige Session ID werden
        bis zu jenem Zeitpunkt gespeichert, in dem das Cookie im Browser manuell durch den jeweiligen Nutzer gelöscht
        wird. Das Bestehen des oAuth-Tokens ist abhängig von Ihrer jeweiligen Nutzung des Portals und läuft
        standardmäßig nach einem Zeitraum von zwei Stunden ab.
      </p>
    </div>

    <div>
      <p className="sub-title">8. Übermittlung Ihrer Daten an externe Empfänger</p>
      <p>
        Der Betrieb bzw. das Hosting dieser Website, der Administrationsoberfläche sowie unserer Datenbank erfolgt durch
        einen externen Dienstleister, der im Rahmen einer Auftragsverarbeitung gem. Art. 28 DSGVO für uns tätig wird.
      </p>
      <p>Kontakt unseres externen IT-Dienstleisters:</p>
      <p className="group">x-ion GmbH</p>
      <p className="group">Marschnerstr. 52</p>
      <p>22081 Hamburg</p>
      <p>
        Die unter Punkt 4, 5 und 6 erhobenen Daten werden unmittelbar an unseren externen Dienstleister übermittelt.
        Diese Verarbeitung Ihrer personenbezogenen Daten erfolgt auf Grundlage unseres berechtigten Interesses gemäß
        Art. 6 Abs. 1 S. 1 lit. f DSGVO, welches sich auf eine Auslagerung unseres Tätigkeitsbereichs an externe
        Dienstleistungsunternehmen erstreckt.
      </p>
    </div>

    <div>
      <p className="sub-title">9. Übermittlung Ihrer Daten an ein Drittland</p>
      <p>
        Darüber hinaus findet eine Übermittlung an weitere externe Dienstleister und/oder sonstige Stellen nicht statt.
        Zudem erfolgt keine Übermittlung Ihrer Daten an ein Drittland außerhalb der Europäischen Union.
      </p>
    </div>

    <div>
      <p className="sub-title">10. Ihre Rechte in Bezug auf Ihre personenbezogenen Daten</p>
      <p>In Bezug auf die Verarbeitung Ihrer personenbezogenen Daten stehen Ihnen die nachfolgenden Rechte zu:</p>
      <p>Recht auf Auskunft, Art. 15 DSGVO</p>
      <p>
        Sie sind dazu berechtigt, von uns eine Auskunft zu verlangen, ob eine Verarbeitung Ihrer personenbezogenen Daten
        durch uns erfolgt. Des Weiteren sind Sie dazu berechtigt, von uns eine Auskunft zu verlangen bezüglich der
        folgenden Punkte: Die jeweiligen Zwecke, zu denen eine Verarbeitung Ihrer Daten erfolgt, die Kategorien Ihrer
        verarbeiteten personenbezogenen Daten, die Empfänger bzw. Kategorien von Empfängern Ihrer Daten (inkl. der an
        Drittländer übermittelten Daten), die geplante Speicherdauer bzw. die Kriterien zur Festlegung der
        Speicherdauer, das Bestehen eines Rechts auf Berichtigung, Löschung oder Einschränkung der Verarbeitung Ihrer
        personenbezogenen Daten, eines Widerspruchsrechts gegen die Verarbeitung, das Bestehen eines Beschwerderechts
        bei einer Aufsichtsbehörde, der Herkunft Ihrer Daten (für den Fall, dass diese nicht bei Ihnen erhoben wurden),
        sowie über das Bestehen einer automatisierten Entscheidungsfindung einschließlich Profiling.
      </p>
      <p>Recht auf Berichtigung, Art. 16 DSGVO</p>
      <p>
        Sie haben das Recht, von uns unverzüglich eine Berichtigung oder Vervollständigung der Sie betreffenden
        personenbezogenen Daten zu verlangen.
      </p>
      <p>Recht auf Löschung, Art. 17 DSGVO</p>
      <p>
        Des Weiteren sind Sie dazu berechtigt, eine Löschung Ihrer personenbezogenen Daten von uns zu verlangen, soweit
        diese Verarbeitung nicht zur Ausübung des Rechts auf freie Meinungsäußerung und Information, zur Erfüllung einer
        rechtlichen Verpflichtung, aus Gründen des öffentlichen Interesses oder zur Geltendmachung, Ausübung oder
        Verteidigung von Rechtsansprüchen erforderlich ist.
      </p>
      <p>Recht auf Einschränkung der Verarbeitung, Art. 18 DSGVO</p>
      <p>
        Sie sind dazu berechtigt, eine Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen, soweit
        Sie die Richtigkeit dieser Daten bestreiten, diese Verarbeitung unrechtmäßig ist und Sie die Löschung der Daten
        ablehnen oder wir die Daten nicht mehr benötigen und Sie diese für eine Geltendmachung, Ausübung oder
        Verteidigung von Rechtsansprüchen benötigen oder sie gem. Art. 21 DSGVO Widerspruch gegen die Verarbeitung
        eingelegt haben.
      </p>
      <p>Recht auf Datenübertragbarkeit, Art. 20 DSGVO</p>
      <p>
        Sie haben das Recht, durch uns die Übertragung der Sie betreffenden personenbezogenen Daten in einem
        strukturierten, gängigen und maschinenlesbaren Format zu fordern oder eine Übermittlung dieser Daten an einen
        weiteren Verantwortlichen zu verlangen, soweit die weiteren Voraussetzungen des Art. 20 DSGVO erfüllt sind.
      </p>
      <p>Widerspruchsrecht, Art. 21 DSGVO</p>
      <p>
        Soweit eine Verarbeitung Ihrer personenbezogenen Daten zur Wahrung unserer berechtigten Interessen nach Art. 6
        Abs. 1 S. 1 lit. f DSGVO erforderlich ist, oder die Verarbeitung für Durchführung von Aufgaben im öffentlichen
        Interesse erforderlich ist, sind Sie jederzeit dazu berechtigt, Widerspruch gegen diese Verarbeitung einzulegen,
        soweit Gründe bestehen, die sich aus Ihrer besonderen Situation ergeben. Sofern Ihre Daten zum Zweck der
        Direktwerbung verarbeitet werden, haben Sie das Recht, jederzeit und ohne Angabe einer weiteren Begründung
        Widerspruch gegen die Verarbeitung einzulegen.
      </p>
      <p>
        Zur Ausübung Ihres Widerspruchsrechts nach Art. 21 DSGVO senden Sie bitte eine E-Mail an die folgende Adresse:
        &nbsp;<span>kontakt@igbce-bonusagentur.de</span>
      </p>

      <p>Widerruf Ihrer Einwilligung</p>
      <p>
        Sie sind gemäß Art. 7 Abs. 3 DSGVO dazu berechtigt, jederzeit Ihre in Bezug auf die Verarbeitung Ihrer
        personenbezogenen Daten erteilte Einwilligung zu widerrufen. Die Rechtmäßigkeit der Datenverarbeitung bleibt bis
        zum Zeitpunkt des Widerrufs unberührt. Dazu senden Sie bitte eine E-Mail mit dem Stichwort “Widerruf” an die
        nachfolgende E-Mail Adresse: <span>kontakt@igbce-bonusagentur.de</span>
      </p>

      <p>Beschwerderecht bei einer Aufsichtsbehörde, Art. 77 DSGVO</p>
      <p>
        Gemäß Art. 77 DSGVO sind Sie dazu berechtigt, bei der zuständigen Aufsichtsbehörde für Datenschutz eine
        Beschwerde in Bezug auf die von uns durchgeführte Verarbeitung Ihrer personenbezogenen Daten einzureichen.
      </p>
    </div>
  </div>
)
