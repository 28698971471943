import React from 'react'
import LogoutBox from '../logout_box'

const Boxes = () => (
  <div id="logout-boxes">
    <div className="box-around">
      <div className="box">
        <LogoutBox product="employment-protection" />
      </div>
      <div className="box">
        <LogoutBox product="bu flex" />
      </div>
      <div className="box">
        <LogoutBox product="vital protection flex" />
      </div>
    </div>
  </div>
)

export default Boxes
