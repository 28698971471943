import axios from 'axios'
import { showFormErrors } from '../../shared/lib/helpers'

function verifyEmail(element) {
  axios({
    method: 'PATCH',
    url: `/resources/profile/${element.props.match.params.id}/verify_email`
  })
    .then((response) => {
      element.setState({ successMessage: response.data.message })
    })
    .catch((error) => {
      element.setState({ errorMessage: error.response.data.message })
    })
}

function handleRegistration(element, data, afterSuccess = null, afterError = null) {
  axios({
    method: 'POST',
    url: `/resources/registrations`,
    data: {
      registration: data
    }
  })
    .then((response) => {
      afterSuccess(response.data)
    })
    .catch((error) => {
      if (error.response.data.errors) {
        showFormErrors(element, error, 'validationErrors')
      } else {
        afterError(error.response.data)
      }
    })
}

function verifyRegistration(element) {
  const data = {
    user: {
      password: element.state.password,
      password_confirmation: element.state.password_confirmation
    }
  }
  axios
    .patch(`/resources/registrations/${element.props.match.params.id}`, data)
    .then((response) => {
      element.setState({ success_message: response.data.success_message })
      element.setState({ showForm: false })
    })
    .catch((error) => {
      if (error.response.data.errors) {
        showFormErrors(element, error, 'validationErrors')
      } else {
        element.setState({ error_message: error.response.data.error_message })
      }
    })
}

export { verifyEmail, handleRegistration, verifyRegistration }
