import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import de from '../../locales/general_de.json'
import buDE from '../../locales/de/bu_de.json'

// Should be romoved in ticket XCON-621
if (process.env.NODE_ENV === 'test') {
  Object.defineProperty(window.navigator, 'languages', { value: ['de'] })
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'de',
    ns: ['general', 'bu'],
    defaultNS: 'general',
    cleanCode: true,
    detection: {
      order: ['localStorage', 'navigator'],
      caches: ['localStorage']
    },
    resources: {
      de: {
        general: de,
        bu: buDE
      }
    },
    load: 'languageOnly',
    lowerCaseLng: true,
    returnObjects: true,
    react: {
      useSuspense: false,
      transKeepBasicHtmlNodesFor: ['br', 'strong', 'i']
    }
  })

export default i18n
