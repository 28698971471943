import React from 'react'
import { PropTypes } from 'prop-types'
import { withRouter } from 'react-router-dom'
import { withCookies } from 'react-cookie'
import { compose } from 'myapos_recompose'
import { verifyPassword, updatePassword } from 'bundles/shared/lib/requests'
import Input from 'bundles/shared/components/form/input'
import { withTranslation } from 'react-i18next'
import { showFormErrors } from '../../../shared/lib/helpers'

class ResetPassword extends React.Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired
  }

  constructor(props) {
    super(props)
    this.state = {
      errorMessage: '',
      password: '',
      password_confirmation: '',
      showForm: false,
      successMessage: '',
      validationErrors: {}
    }
  }

  componentDidMount() {
    verifyPassword(this.props.match.params.id, this.onVerifySuccess, this.onVerifyError)
  }

  onVerifySuccess = () => {
    this.setState({ showForm: true })
  }

  onVerifyError = (error) => {
    this.setState({ errorMessage: error.error_message })
  }

  onChange = (value, error, attr) => {
    let validationErrors = this.state.validationErrors
    validationErrors[attr] = error
    this.setState({ [attr]: value, validationErrors })
  }

  handleSubmit = (event) => {
    event.preventDefault()
    let data = {
      owner: {
        password: this.state.password,
        password_confirmation: this.state.password_confirmation
      }
    }
    updatePassword(this.props.match.params.id, data, this.onSubmitSuccess, this.onSubmitError)
  }

  onSubmitSuccess = (data) => {
    this.setState({ showForm: false, successMessage: data.success_message })
  }

  onSubmitError = (error) => {
    if (error.response.data.errors) {
      showFormErrors(this, error, 'validationErrors')
    } else {
      this.setState({ showForm: false, errorMessage: error.response.data.error_message })
    }
  }

  render() {
    return (
      <div className="box login-box">
        {!!this.state.errorMessage && <div className="error_message">{this.state.errorMessage}</div>}
        {!!this.state.successMessage && <div className="success-message">{this.state.successMessage}</div>}
        {this.state.showForm && (
          <form onSubmit={this.handleSubmit}>
            <div className="password-info-text">{this.props.t('signed_off.reset_password.prompt')}</div>
            <Input
              key="password"
              labelPrefix="settings.profile.attributes"
              attr="password"
              validationErrors={this.state.validationErrors}
              value={this.state.password}
              onChange={this.onChange}
              type="password"
            />
            <Input
              key="password_confirmation"
              labelPrefix="settings.profile.attributes"
              attr="password_confirmation"
              validationErrors={this.state.validationErrors}
              value={this.state.password_confirmation}
              onChange={this.onChange}
              type="password"
            />
            <input type="submit" value={this.props.t('buttons.confirm')} className="btn" />
          </form>
        )}
        <div>
          <a href="/login">
            <span>{this.props.t('session.back_to_login_link')}</span>
          </a>
        </div>
      </div>
    )
  }
}

export default compose(withCookies, withRouter, withTranslation())(ResetPassword)
