import React from 'react'
import PropTypes from 'prop-types'

export const PageNotFound = ({ ...props }) => (
  <div id="page-not-found">
    <div>
      <p>
        {props.title_1} <br />
        {props.title_2}
      </p>
      <p>
        {props.sub_title_1} <br />
        <a href="/">{props.sub_title_2}</a>
      </p>
    </div>
  </div>
)

PageNotFound.propTypes = {
  title_1: PropTypes.string.isRequired,
  title_2: PropTypes.string.isRequired,
  sub_title_1: PropTypes.string.isRequired,
  sub_title_2: PropTypes.string.isRequired
}
