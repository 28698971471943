function showFormErrors(element, error, stateErrorName = null) {
  if (error.response) {
    const data = error.response.data
    if (data) {
      if (data.errors) {
        element.setState({ [stateErrorName]: data.errors })
      }
    }
  } else {
    console.log(error)
  }
}

export { showFormErrors }
