import React from 'react'
import { PropTypes } from 'prop-types'
import { Link, withRouter } from 'react-router-dom'
import { withCookies } from 'react-cookie'
import { compose } from 'myapos_recompose'
import { withTranslation } from 'react-i18next'
import { verifyEmail } from '../../lib/requests'

export class EmailVerification extends React.Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired
  }

  static CONSULTANT_AUTO_ACTIVATED = 'consultantAutoActivated'

  static CONSULTANT_PENDING = 'consultantPending'

  constructor(props) {
    super(props)

    this.loginBoxRef = React.createRef()
    this.state = {
      errorMessage: '',
      successMessage: '',
      messageCode: '',
      consultant: {
        name: ''
      }
    }
  }

  componentDidMount() {
    verifyEmail(this.props.match.params.id, this.onSuccessHandler, this.onErrorHandler)
  }

  onSuccessHandler = (data) => {
    this.setState({
      successMessage: data.message,
      messageCode: data.messageCode,
      consultant: { name: data.consultant.name }
    })
    this.scrollToLoginBoxRef()
  }

  onErrorHandler = (data) => {
    this.setState({ errorMessage: data.message })
    this.scrollToLoginBoxRef()
  }

  scrollToLoginBoxRef = () => {
    this.loginBoxRef.current.scrollIntoView({ block: 'end', behavior: 'smooth' })
  }

  renderSuccessMessage() {
    switch (this.state.messageCode) {
      case EmailVerification.CONSULTANT_PENDING:
        return (
          <>
            Wir freuen uns, dass Sie Ihren Kunden eine Beratung der Vorsorgelösungen von AKS Flex ermöglichen. Nachdem
            wir Ihre Registrierung <span className="strong">innerhalb von 48 Stunden überprüft</span> haben, erhalten
            Sie eine <span className="strong">Bestätigungsmail</span> mit der Zugriffsberechtigung für das
            Beraterportal.
          </>
        )
      case EmailVerification.CONSULTANT_AUTO_ACTIVATED:
        return (
          <>
            <p>Wir freuen uns, dass Sie Ihren Kunden eine Beratung der Vorsorgelösungen von AKS Flex ermöglichen.</p>
            <p>
              Ihr Account wurde aktiviert. Sie können sich jetzt auf dem <a href="/">Beraterportal</a> einloggen.
            </p>
          </>
        )
    }
  }

  render() {
    const t = this.props.t
    return (
      <div className="box login-box" ref={this.loginBoxRef}>
        <div>
          <Link to="/login">{t('bu:user.login_button')}</Link>
          <Link to="/register" className="active">
            {t('bu:user.register')}
          </Link>
        </div>

        {!!this.state.errorMessage && <div className="error_message register-message">{this.state.errorMessage}</div>}
        {!!this.state.successMessage && (
          <div id="success-register">
            <p className="title">
              {t('bu:user.register_success.title_1')}, {this.state.consultant.name},&nbsp;
              {t('bu:user.register_success.title_2')}
            </p>
            <p>{this.renderSuccessMessage()}</p>
            <br />
            <p>
              Viel Erfolg bei den kommenden Gesprächen wünscht Ihnen
              <br />
              Ihr AKS Flex-Team
            </p>
          </div>
        )}
      </div>
    )
  }
}

export default compose(withCookies, withRouter, withTranslation())(EmailVerification)
