import Cookies from 'universal-cookie'
import { ACTIVATE_MENU_ITEM, UPDATE } from './action_types'
import { store } from './store'

const confirmCookieConsent = () => {
  const cookies = new Cookies()
  cookies.set('cookieConsentConfirmed', true, { path: '/' })
  return {
    type: UPDATE,
    payload: { cookieConsentConfirmed: true }
  }
}

const deleteAddress = (addressId, type) => {
  let addresses
  let others
  if (type === 'billing') {
    const { billingAddresses, ...rest } = store.getState().xCRM
    addresses = billingAddresses
    others = rest
  } else {
    const { shippingAddresses, ...rest } = store.getState().xCRM
    addresses = shippingAddresses
    others = rest
  }
  const ids = addresses.map((address) => address.id)
  if (ids.includes(addressId)) {
    const newAddresses = addresses.filter((address) => address.id !== addressId)
    return {
      type: UPDATE,
      payload: { xCRM: { ...others, [`${type}Addresses`]: newAddresses } }
    }
  }
}

const filterEmployees = (filter) => {
  const { employees, employeeFilterType, employeeKeywords, employeeFilterId, ...rest } = store.getState().xCRM
  const filterType = filter.filterType || employeeFilterType
  const filterKeywords = filter.filterKeywords || employeeKeywords
  const filteredEmployees = setEmployeeListDup(employees, filterType, filterKeywords, employeeFilterId)
  return {
    type: UPDATE,
    payload: {
      xCRM: {
        ...rest,
        employeesDup: filteredEmployees,
        employees,
        employeeFilterType: filterType,
        employeeKeywords: filterKeywords,
        employeeFilterId
      }
    }
  }
}

const handleServiceClick = (activatedService) => {
  window.location.href = redirectedRoute(activatedService)
  return {
    type: UPDATE,
    payload: {}
  }
}

// Todo
// make domain dynamic

const handleSignIn = (token, xapps) => {
  const cookies = new Cookies()
  let endpointVersion
  cookies.set('oauthToken', token, { path: '/', domain: store.getState().rootDomain })
  for (let app in xapps) {
    endpointVersion = Object.keys(xapps[app])[0]
    if (endpointVersion === 'v1') {
      cookies.set(app, xapps[app].v1, { path: '/', domain: store.getState().rootDomain })
    }
  }

  return {
    type: UPDATE,
    payload: {}
  }
}

const handleSignOut = () => {
  const cookies = new Cookies()
  cookies.set('oauthToken', '', { path: '/', domain: store.getState().rootDomain })
  return {
    type: UPDATE,
    payload: { oauthToken: '', isSignedIn: false }
  }
}

const handleSubMenuItemClick = (service, menuItem) => ({
  type: ACTIVATE_MENU_ITEM,
  payload: { service, menuItem }
})

const initializeAddresses = (list, type) => ({
  type: UPDATE,
  payload: { xCRM: { ...store.getState().xCRM, [`${type}Addresses`]: list } }
})

const initializeCustomer = (customer) => ({
  type: UPDATE,
  payload: { xCRM: { ...store.getState().xCRM, customer } }
})

const initializeEmployeeList = (list, filteredId) => {
  let employeesDup = filteredId && list.filter((employee) => employee.id === filteredId)
  return {
    type: UPDATE,
    payload: {
      xCRM: {
        ...store.getState().xCRM,
        employees: list,
        employeesDup: employeesDup || list,
        employeeFilterId: filteredId
      }
    }
  }
}

const initialStates = (states) => ({
  type: UPDATE,
  payload: states
})

const initialTabs = () => ({
  type: UPDATE,
  payload: {
    xCRM: { ...store.getState().xCRM, billingAddresses: [], employees: [], employeesDup: [], shippingAddresses: [] }
  }
})

const initializeTabs = () => ({
  type: UPDATE,
  payload: {
    xCRM: {
      ...store.getState().xCRM,
      billingAddresses: [],
      employees: [],
      employeesDup: [],
      shippingAddresses: []
    }
  }
})

const redirectedRoute = (service) => {
  let cookies
  switch (service) {
    case 'xDNS':
      return '/xdns/zones'
    case 'xSMS':
      return '/xsms'
    case 'xCRM':
      return '/xcrm/potential_customers'
    case 'xVBE':
      cookies = new Cookies()
      return cookies.get('xVBE')
  }
}

const removeCustomer = (id, type) => {
  let customers
  let otherXcrmData
  if (type === 'potentialCustomers') {
    let { potentialCustomers, ...rest } = store.getState().xCRM
    customers = potentialCustomers
    otherXcrmData = rest
  } else if (type === 'contractualCustomers') {
    let { contractualCustomers, ...rest } = store.getState().xCRM
    customers = contractualCustomers
    otherXcrmData = rest
  } else {
    throw 'currently not supported customer type'
  }
  delete customers[id]
  localStorage.setItem(type, JSON.stringify(customers))
  return {
    type: UPDATE,
    payload: {
      xCRM: {
        ...otherXcrmData,
        [type]: customers
      }
    }
  }
}

const resetFlash = () => ({
  type: UPDATE,
  payload: { flashMessage: null, flashType: null }
})

const setAddressesList = (list, type) => {
  let addresses
  let others
  if (type === 'billing') {
    let { billingAddresses, ...rest } = store.getState().xCRM
    addresses = billingAddresses
    others = rest
  } else {
    let { shippingAddresses, ...rest } = store.getState().xCRM
    addresses = shippingAddresses
    others = rest
  }
  let ids = addresses.map((e) => e.id)
  if (ids.includes(list[0].id)) {
    addresses[ids.indexOf(list[0].id)] = list[0]
  } else {
    addresses = [...addresses, ...list]
  }
  return {
    type: UPDATE,
    payload: { xCRM: { ...others, [`${type}Addresses`]: addresses } }
  }
}

const setCustomersList = (list, type, filter) => {
  const listGroupedById = Object.assign({}, ...list.map((c) => ({ [c.id]: c })))
  let oldState = store.getState().xCRM
  localStorage.setItem(type, JSON.stringify(listGroupedById))
  return {
    type: UPDATE,
    payload: { xCRM: { ...oldState, [type]: listGroupedById, [`${type}Filter`]: filter } }
  }
}

const setCustomer = (customer, type) => {
  let customers
  let otherXcrmData
  if (type === 'potentialCustomers') {
    let { potentialCustomers, ...rest } = store.getState().xCRM
    customers = potentialCustomers
    otherXcrmData = rest
  } else if (type === 'contractualCustomers') {
    let { contractualCustomers, ...rest } = store.getState().xCRM
    customers = contractualCustomers
    otherXcrmData = rest
  } else {
    throw 'currently not supported customer type'
  }
  customers[customer.id] = customer
  localStorage.setItem(type, JSON.stringify(customers))
  return {
    type: UPDATE,
    payload: { xCRM: { ...otherXcrmData, [type]: customers } }
  }
}

const setEmployeeList = (list) => {
  let { employees, ...rest } = store.getState().xCRM
  let ids = employees.map((e) => e.id)
  if (ids.includes(list[0].id)) {
    employees[ids.indexOf(list[0].id)] = list[0]
  } else {
    employees = [...employees, ...list]
  }
  const employeesDup = setEmployeeListDup(employees, rest.employeeFilterType, rest.employeeKeywords)
  return {
    type: UPDATE,
    payload: { xCRM: { ...rest, employees, employeesDup } }
  }
}

const setEmployeeListDup = (employees, filterType, filterKeywords, filterId) => {
  let matchedType
  let matchedKey
  let filteredEmployees = []
  employees.filter((employee) => {
    const matchedId = filterId ? employee.id === filterId : true
    if (matchedId) {
      switch (filterType) {
        case 'contact_person':
          matchedType = employee.roles.length !== 0
          break
        case 'authorized':
          matchedType = employee.authorizing
          break
        default:
          matchedType = true
      }
      if (matchedType) {
        if (filterKeywords.length > 0) {
          for (let key of filterKeywords) {
            matchedKey = employee.email.toLowerCase().includes(key) || employee.name.toLowerCase().includes(key)
            if (matchedKey) {
              filteredEmployees.push(employee)
              return
            }
          }
        } else {
          filteredEmployees.push(employee)
        }
      }
    }
  })
  return filteredEmployees
}

const setLocale = (locale) => ({
  type: UPDATE,
  payload: { locale }
})

const setRootDomain = (domain) => ({
  type: UPDATE,
  payload: { rootDomain: domain }
})

const setFlash = (type, message) => ({
  type: UPDATE,
  payload: { flashMessage: message, flashType: type }
})

const toggleSideNavigation = (oldState) => ({
  type: UPDATE,
  payload: { sideNavIsShown: !oldState }
})

const setCurrentUser = (currentUser) => ({
  type: UPDATE,
  payload: { currentUser }
})

const setDialogOverlay = (overlay) => {
  let options = { ...store.getState().options, dialogOverlay: overlay }
  return {
    type: UPDATE,
    payload: { options }
  }
}

const setActivePath = (pathName) => {
  let options = { ...store.getState().options, pathName }
  return {
    type: UPDATE,
    payload: { options }
  }
}

export {
  confirmCookieConsent,
  deleteAddress,
  filterEmployees,
  handleSignIn,
  handleServiceClick,
  handleSignOut,
  handleSubMenuItemClick,
  initializeAddresses,
  initializeCustomer,
  initializeEmployeeList,
  initialStates,
  initializeTabs,
  removeCustomer,
  resetFlash,
  setAddressesList,
  setCurrentUser,
  setCustomer,
  setCustomersList,
  setDialogOverlay,
  setEmployeeList,
  setFlash,
  setLocale,
  setRootDomain,
  setActivePath,
  toggleSideNavigation
}
