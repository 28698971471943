import axios from 'axios/index'
import ReactOnRails from 'react-on-rails'

function signOut(event, element) {
  event.preventDefault()
  simpleSignOut(element.props.handleSignOut)
}

function simpleSignOut(handleSuccess) {
  axios({
    method: 'DELETE',
    url: '/api/logout',
    headers: { 'X-CSRF-Token': ReactOnRails.authenticityToken() }
  })
    .then((_response) => {
      handleSuccess && handleSuccess()
      window.location.href = '/verwaltung'
    })
    .catch((error) => {
      console.log(error)
    })
}

function consultantSignOut(event, handleSuccess, afterSuccess) {
  event.preventDefault()
  axios({
    method: 'DELETE',
    url: '/api/consultants/logout',
    headers: { 'X-CSRF-Token': ReactOnRails.authenticityToken() }
  })
    .then((_response) => {
      handleSuccess && handleSuccess()
      afterSuccess && afterSuccess()
    })
    .catch((error) => {
      console.log(error)
    })
}

function signIn(event, element) {
  event.preventDefault()
  if (!element.state.disabled) {
    element.setState({ disabled: true })
    const formData = new FormData(event.target)
    axios({
      method: 'POST',
      url: '/api/login',
      headers: { 'X-CSRF-Token': ReactOnRails.authenticityToken(), 'X-Requested-With': 'XMLHttpRequest' },
      data: formData
    })
      .then((response) => {
        const data = response.data
        if (data.token === undefined) {
          element.setState({ authError: data.message })
        } else {
          element.props.handleSignIn(data.token, data.xapps)
          window.location.href = '/consultant-management/pending'
        }
      })
      .catch((error) => {
        console.log(error)
        element.setState({ authError: error.response.data.message })
        setTimeout(() => element.setState({ disabled: false }), 1000)
      })
  }
}

function resetPassword(email, afterSuccess = null) {
  axios({
    method: 'POST',
    url: '/resources/password_resets',
    data: { email }
  })
    .then((response) => {
      afterSuccess(response.data)
    })
    .catch((error) => {
      console.log(error)
    })
}

function verifyPassword(token, afterSuccess = null, afterError = null) {
  axios
    .get(`/resources/password_resets/${token}/verify`)
    .then((_response) => {
      afterSuccess()
    })
    .catch((error) => {
      afterError(error.response.data)
    })
}

function updatePassword(token, data, afterSuccess = null, afterError = null) {
  axios
    .patch(`/resources/password_resets/${token}`, data)
    .then((response) => {
      afterSuccess(response.data)
    })
    .catch((error) => {
      afterError(error)
    })
}

export { signIn, signOut, simpleSignOut, resetPassword, verifyPassword, updatePassword, consultantSignOut }
