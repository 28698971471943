import React from 'react'
import PropTypes from 'prop-types'
import Validator from 'bundles/shared/helpers/validator'
import { withTranslation } from 'react-i18next'
import { FormError } from './form_error'

class Input extends React.Component {
  static propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    disabled: PropTypes.bool,
    labelPrefix: PropTypes.string.isRequired,
    validationErrors: PropTypes.object.isRequired,
    value: PropTypes.string,
    attr: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    validates: PropTypes.object,
    t: PropTypes.func.isRequired,
    type: PropTypes.string
  }

  validator = new Validator(this.props.validates)

  constructor(props) {
    super(props)
    this.labelName = this.props.t(`${this.props.labelPrefix}.${this.props.attr}`)
  }

  onChange = (e) => {
    this.props.onChange && this.props.onChange(e.target.value, this.validate(e.target.value), this.props.attr)
  }

  errorMessage = () => {
    if (this.props.validationErrors[this.props.attr]) {
      const errorMessage = this.props.validationErrors[this.props.attr].join(' ')
      return <FormError error={errorMessage} />
    }
    return ''
  }

  validate = (value) => this.validator.validate(value)

  render() {
    return (
      <label className={this.props.validationErrors[this.props.attr] ? 'error' : null}>
        {this.props.children ? this.props.children : this.labelName}
        <input
          value={this.props.value}
          onChange={this.onChange}
          type={this.props.type || 'text'}
          disabled={this.props.disabled}
          placeholder={this.props.placeholder}
          name={this.props.attr}
        />
        {this.errorMessage()}
      </label>
    )
  }
}

export default withTranslation()(Input)
