import React from 'react'
import { PropTypes } from 'prop-types'
import { Provider } from 'react-redux'
import { store } from 'bundles/shared/lib/redux/store'

export const withRedux = (WrappedRoutes) =>
  class extends React.Component {
    static propTypes = {
      current_user: PropTypes.object,
      locale: PropTypes.string.isRequired,
      root_domain: PropTypes.string.isRequired
    }

    render() {
      return (
        <Provider store={store}>
          <WrappedRoutes
            locale={this.props.locale}
            root_domain={this.props.root_domain}
            current_user={this.props.current_user}
          />
        </Provider>
      )
    }
  }
