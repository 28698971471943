import React from 'react'
import { PropTypes } from 'prop-types'
import { Link, withRouter } from 'react-router-dom'
import { withCookies } from 'react-cookie'
import { compose } from 'myapos_recompose'
import { withTranslation } from 'react-i18next'
import { verifyConsultantEmail } from '../../lib/requests'

class EmailChangeVerification extends React.Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired
  }

  constructor(props) {
    super(props)
    this.state = {
      errorMessage: '',
      successMessage: ''
    }
  }

  componentDidMount() {
    verifyConsultantEmail(this.props.match.params.id, this.onSuccessHandler, this.onErrorHandler)
  }

  onSuccessHandler = (data) => {
    this.setState({ successMessage: data.message })
  }

  onErrorHandler = (data) => {
    this.setState({ errorMessage: data.message })
  }

  render() {
    const t = this.props.t
    return (
      <div className="box login-box">
        <div>
          <Link to="/login">{t('bu:user.login_button')}</Link>
          <Link to="/register" className="active">
            {t('bu:user.register')}
          </Link>
        </div>

        {!!this.state.errorMessage && <div className="error_message register-message">{this.state.errorMessage}</div>}
        {!!this.state.successMessage && (
          <div id="success-register">
            <p className="title">Herzlichen Dank für die Aktualisierung Ihrer Daten.</p>
            <p>
              Ihre neue E-Mail-Adresse ist jetzt aktiv. Bitte bedenken Sie, dass Sie sich ab sofort nicht mehr mit der
              alten E-Mail-Adresse anmelden können.
            </p>
            <br />
            <p>Viel Erfolg weiterhin wünscht Ihnen Ihr AKS Flex-Team.</p>
          </div>
        )}
      </div>
    )
  }
}

export default compose(withCookies, withRouter, withTranslation())(EmailChangeVerification)
