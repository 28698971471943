import React from 'react'
import { PropTypes } from 'prop-types'
import LogoIcon from 'icons/bu/aks_flex.svg'
import { withTranslation } from 'react-i18next'

const Header = ({ t }) => (
  <div id="header">
    <a href="/">
      <LogoIcon className="logo" />
    </a>
    <div className="header-link">
      <h3>{t('bu:mainpage.header.info')}</h3>
    </div>
  </div>
)

Header.propTypes = {
  t: PropTypes.func.isRequired
}

export default withTranslation()(Header)
