import React from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

export const Footer = ({ isSignedIn, t, imprint, privacyPolicy, imprintLinkText, privacyPolicyLinkText }) => (
  <div id="footer" className={isSignedIn ? 'sign-in-footer' : 'sign-out-footer'}>
    <a href={privacyPolicy}>{privacyPolicyLinkText || t('privacy_policy')}</a>
    <a href={imprint}>{imprintLinkText || t('imprint')}</a>
  </div>
)

Footer.propTypes = {
  isSignedIn: PropTypes.bool.isRequired,
  imprint: PropTypes.string.isRequired,
  privacyPolicy: PropTypes.string.isRequired,
  imprintLinkText: PropTypes.string,
  privacyPolicyLinkText: PropTypes.string,
  t: PropTypes.func.isRequired
}

export default withTranslation()(Footer)
