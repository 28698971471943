import React from 'react'

export const LegalNotice = () => (
  <div id="legal-notice">
    <p className="title">Impressum</p>

    <p className="company-name">IG BCE Bonusagentur GmbH</p>

    <div>
      <p>Geschäftsführung: Andrea Pichottka, Peter Voigt</p>
      <p>Fischerstraße 5</p>
      <p>30167 Hannover</p>
    </div>

    <div>
      <p>Tel.: 0511 7631-342</p>
      <p>Fax: 0511 7631-794</p>
    </div>

    <p>E-Mail: kontakt(at)igbce-bonusagentur.de</p>

    <p>Verantwortlich für den Inhalt gemäß Telemediengesetz (TMG)</p>

    <p>Andrea Pichottka</p>

    <div>
      <p>Registergericht: Hannover</p>
      <p>Handelsregisternr.: HRB 208565</p>
    </div>

    <div>
      <p>Steuernummer: 25/203/55640</p>
      <p>USt-Ident-Nummer: DE285197262</p>
    </div>

    <p className="sub-title">Gestaltung, technische Konzeption & Realisierung</p>

    <p className="company-name">x-ion GmbH</p>

    <div>
      <p>Reeperbahn 1</p>
      <p>20359 Hamburg</p>
    </div>
  </div>
)
