import React from 'react'
import PropTypes from 'prop-types'
import Validator from 'bundles/shared/helpers/validator'
import { withTranslation } from 'react-i18next'

class BuFlexInput extends React.Component {
  static propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    disabled: PropTypes.bool,
    labelPrefix: PropTypes.string.isRequired,
    validationErrors: PropTypes.object.isRequired,
    value: PropTypes.string,
    attr: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    placeholder: PropTypes.string,
    validates: PropTypes.object,
    type: PropTypes.string,
    t: PropTypes.func.isRequired
  }

  validator = new Validator(this.props.validates)

  constructor(props) {
    super(props)
    this.labelName = this.props.t(`${this.props.labelPrefix}.${this.props.attr}`)
  }

  onChange = (e) => {
    const errors = this.props.onBlur ? [] : this.validate(e.target.value)
    this.props.onChange && this.props.onChange(e.target.value, errors, this.props.attr)
  }

  onBlur = (e) => {
    this.props.onBlur && this.props.onBlur(e.target.value, this.validate(e.target.value), this.props.attr)
  }

  errorMessage = () => {
    let error = this.props.validationErrors[this.props.attr]
    if (Array.isArray(error) && error.length) {
      return this.props.validationErrors[this.props.attr]
    }
  }

  validate = (value) => this.validator.validate(value)

  render() {
    return (
      <label className={this.props.validationErrors[this.props.attr] ? 'error' : null}>
        {this.props.children ? this.props.children : this.labelName}
        <input
          value={this.props.value}
          onChange={this.onChange}
          onBlur={this.onBlur}
          type={this.props.type || 'text'}
          disabled={this.props.disabled}
          placeholder={this.props.placeholder}
          name={this.props.attr}
          className={this.errorMessage() ? 'error' : ''}
        />
      </label>
    )
  }
}

export default withTranslation()(BuFlexInput)
