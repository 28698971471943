import i18next from 'i18next'

class ConfirmationValidator {
  constructor(value, options) {
    this.options = options || {}
    this.value = value
    this.confirmationValue = options.element.state[`${options.attr}`] || {}
  }

  errorMessage = () => {
    if (this.value === this.confirmationValue || !this.value) {
      return ''
    }
    return this.options.message || i18next.t('validator.password_confirmation.confirmation')
  }
}

class CharLengthValidator {
  constructor(value, options) {
    this.options = options || {}
    this.value = value
    this.min = options.min || 0
    this.max = options.max || 65535
  }

  errorMessage = () => {
    if (!!this.value && this.value.length < this.min) {
      return i18next.t('validator.password.too_short')
    }
    if (!!this.value && this.value.length > this.max) {
      return i18next.t('validator.max_char_length')
    }
    return ''
  }
}

class PresenceValidator {
  constructor(value, options) {
    this.options = options || {}
    this.value = value
  }

  errorMessage = () => {
    if (this.value) {
      return ''
    }
    return this.options.message || i18next.t('validator.email.blank')
  }
}

class ValidEmailValidator {
  constructor(value, options) {
    this.options = options || {}
    this.value = value
  }

  errorMessage = () => {
    if (!!this.value.match(/.+@.+\..+/) || !this.value) {
      return ''
    }
    return this.options.message || i18next.t('validator.email.invalid_email')
  }
}

export default {
  ConfirmationValidator,
  CharLengthValidator,
  PresenceValidator,
  ValidEmailValidator
}
