export const reducer = (state, action) => {
  let _newState
  let serviceName
  let activatedMenuItem
  let oldNavItmes

  switch (action.type) {
    case 'ACTIVATE_MENU_ITEM':
      serviceName = action.payload.service
      activatedMenuItem = action.payload.menuItem
      oldNavItmes = { ...state[serviceName].SubNavItems }
      Object.keys(oldNavItmes).forEach((item) => {
        oldNavItmes[item].active = activatedMenuItem === item
      })
      _newState = { ...state[serviceName].SubNavItems, ...oldNavItmes }
      return state
    default:
      return { ...state, ...action.payload }
  }
}
