import React from 'react'
import { PropTypes } from 'prop-types'
import ErrorIcon from 'icons/bu/error_icon.svg'

const ErrorTooltip = ({ errorMessage }) => (
  <>
    {errorMessage ? (
      <div className="info-svg tooltip">
        <ErrorIcon className="error" />
        <span className="tooltiptext">{errorMessage}</span>
      </div>
    ) : (
      <div className="info-svg tooltip" />
    )}
  </>
)

ErrorTooltip.propTypes = {
  errorMessage: PropTypes.string.isRequired
}

export default ErrorTooltip
