import React from 'react'
import PropTypes from 'prop-types'

export const Checkbox = ({ ...props }) => (
  <div className={props.divClass}>
    <label className={`checkbox-container ${(props.disabled && 'disabled') || ''}`} htmlFor={props.labelFor}>
      {props.iconName ? props.iconName : props.labelName}
      <input
        type="checkbox"
        id={props.labelFor}
        name={props.name}
        checked={props.checked || false}
        disabled={props.disabled}
        onChange={props.handleChange}
      />
      <span className="checkmark" />
      {props.checkmarkText && <span className="text">{props.checkmarkText}</span>}
    </label>
    {props.tooltipText && <span className="tooltip">{props.tooltipText}</span>}
  </div>
)

Checkbox.propTypes = {
  labelFor: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  labelName: PropTypes.string,
  checkmarkText: PropTypes.string,
  handleChange: PropTypes.func,
  divClass: PropTypes.string,
  iconName: PropTypes.element,
  tooltipText: PropTypes.string,
  disabled: PropTypes.bool
}
