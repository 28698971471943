import React from 'react'
import { PropTypes } from 'prop-types'
import { connect } from 'react-redux'
import Input from 'bundles/bu_flex_dashboard/components/form/input'
import { Link } from 'react-router-dom'
import { CSSTransition } from 'react-transition-group'
import { handleSignIn } from 'bundles/shared/lib/redux/actions'
import ErrorTooltip from 'bundles/bu_flex_dashboard/components/form/error_tooltip'
import { withTranslation } from 'react-i18next'
import PasswordResetForm from './password_reset_form'
import { signIn } from '../../lib/requests'

function mapDispatchToProps(dispatch) {
  return {
    handleSignIn: (token, xapps) => dispatch(handleSignIn(token, xapps))
  }
}

class Login extends React.Component {
  static propTypes = {
    handleSignIn: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired
  }

  constructor(props) {
    super(props)
    this.state = {
      validationErrors: {},
      showLoginDialog: true,
      showResetDialog: false,
      disabled: false,
      session: {
        login: '',
        password: ''
      }
    }
  }

  onSignIn = (event) => {
    event.preventDefault()
    if (!this.state.disabled) {
      signIn(this.state.session, this.onSubmitSuccess, this.onSubmitError)
      this.setState({ disabled: true })
    }
  }

  onSubmitSuccess = (data) => {
    this.props.handleSignIn(data.token, data.xapps)
    window.location.href = '/'
  }

  onSubmitError = (error) => {
    this.setState({ authError: error.message })
    setTimeout(() => this.setState({ disabled: false }), 1000)
  }

  onChange = (value, error, attr) => {
    let validationErrors = this.state.validationErrors
    validationErrors[attr] = error
    let session = { ...this.state.session, [attr]: value }
    this.setState({ session, validationErrors })
  }

  backToLogin = () => {
    this.setState({ showResetDialog: false })
  }

  render() {
    const t = this.props.t
    return (
      <>
        <div className="login-info">
          {this.state.showLoginDialog && (
            <div className="login-box">
              <div>
                <Link to="/login" className="active">
                  {t('bu:user.login_button')}
                </Link>
                <Link to="/register">{t('bu:user.register')}</Link>
              </div>
              <form onSubmit={this.onSignIn}>
                <div className="input-info-field">
                  <Input
                    labelPrefix="bu:user"
                    validationErrors={this.state.validationErrors}
                    attr="login"
                    onChange={this.onChange}
                  />
                  <ErrorTooltip errorMessage={this.state.authError} />
                </div>
                <div className="input-info-field">
                  <Input
                    labelPrefix="bu:user"
                    validationErrors={this.state.validationErrors}
                    attr="password"
                    type="password"
                    onChange={this.onChange}
                  />
                  <div className="info-svg tooltip" />
                </div>
                <input type="submit" disabled={this.state.disabled} value={t('bu:user.login_button')} className="btn" />
              </form>
              <div>
                <a onClick={() => this.setState({ showResetDialog: true })}>{t('bu:user.forget_password')}</a>
              </div>
            </div>
          )}
          <CSSTransition
            in={this.state.showResetDialog}
            key="password-reset"
            classNames="landing"
            timeout={{ enter: 400, exit: 200 }}
            unmountOnExit
            onEnter={() => this.setState({ showLoginDialog: false })}
            onExited={() => this.setState({ showLoginDialog: true })}>
            <PasswordResetForm onBackToLoginClick={this.backToLogin} />
          </CSSTransition>
          <div className="support-info">
            <div>
              <span> {t('bu:user.support.text1')} </span>
              <span className="bold"> {t('bu:user.support.text2')} </span>
              <span> {t('bu:user.support.text3')} </span>
            </div>
            <div className="bold text-box phone">
              <div>
                <span className="consortium"> {t('bu:user.support.swiss_life')} </span>
                <span> {t('bu:user.support.swiss_life_number')} </span>
              </div>
              <div>
                <span className="consortium"> {t('bu:user.support.r_u_v')} </span>
                <span> {t('bu:user.support.r_u_v_number')} </span>
              </div>
              <div>
                <span className="consortium"> {t('bu:user.support.allianz')} </span>
                <span className="allianz-info">Bitte wenden Sie sich an Ihren Betreuer</span>
              </div>
              <div>
                <span className="consortium"> {t('bu:user.support.igbce')} </span>
                <span> {t('bu:user.support.igbce_number')} </span>
              </div>
              <div>
                <span className="consortium"> {t('bu:user.support.bonusassekuranz')} </span>
                <span> {t('bu:user.support.bonusassekuranz_number')} </span>
              </div>
            </div>
            <div className="text-box">{t('bu:user.support.mail_info')}</div>
            <div className="bold underline text-box">{t('bu:user.support.mail')}</div>
          </div>
        </div>
      </>
    )
  }
}

export default connect(null, mapDispatchToProps)(withTranslation()(Login))
