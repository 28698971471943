import ReactOnRails from 'react-on-rails'
import axios from 'axios/index'
import humps from 'humps'

function signIn(loginData, afterSuccess = null, afterError = null) {
  let data = {
    session: loginData
  }
  axios
    .post('/api/consultants/login', data, {
      headers: { 'X-CSRF-Token': ReactOnRails.authenticityToken(), 'X-Requested-With': 'XMLHttpRequest' }
    })
    .then((response) => {
      const responseData = response.data
      if (responseData.token === undefined) {
        afterError(responseData)
      } else {
        afterSuccess(responseData)
      }
    })
    .catch((error) => {
      console.log('loginError', error)
      afterError(error.response.data)
    })
}

function getConsortiums(afterSuccess) {
  axios
    .get('/resources/companies')
    .then((resource) => {
      afterSuccess(resource.data.companies)
    })
    .catch((error) => {
      console.log(error)
    })
}

function verifyEmail(id, afterSuccess, afterError) {
  axios
    .patch(
      `/resources/registrations/${id}`,
      {},
      {
        transformResponse: [...axios.defaults.transformResponse, (data) => humps.camelizeKeys(data)]
      }
    )
    .then((response) => {
      afterSuccess(response.data)
    })
    .catch((error) => {
      afterError(error.response.data)
    })
}

function verifyConsultantEmail(token, afterSuccess, afterError) {
  axios
    .patch(`/resources/consultants/profiles/${token}/verify_email`)
    .then((response) => {
      afterSuccess(response.data)
    })
    .catch((error) => {
      afterError(error.response.data)
    })
}

export { signIn, getConsortiums, verifyEmail, verifyConsultantEmail }
