import React from 'react'
import PropTypes from 'prop-types'
import BuFlexIcon from 'icons/bu/bu_flex.svg'
import VitalProtectionFlexIcon from 'icons/bu/vital_protection_flex.svg'
import CheckIcon from 'icons/bu/check.svg'
import AllianzImage from 'icons/bu/allianz.png'
import RuVImage from 'icons/bu/r+v.png'
import SwissLifeImage from 'icons/bu/swiss_life.png'

class LogoutBox extends React.Component {
  renderBoxContent = (status) => {
    switch (status) {
      case 'bu flex':
        return (
          <div className="box-content">
            <div className="logo">
              <BuFlexIcon />
            </div>
            <div className="product-title">
              BU Flex ist die Berufsunfähigkeitsabsicherung für die Beschäftigten in den der IG BCE angeschlossenen
              Branchen und deren Familienangehörigen.
            </div>
            <div className="product-text">
              <table>
                <tbody>
                  <tr>
                    <th>
                      <CheckIcon />
                    </th>
                    <th> Günstige Absicherung zu Großkundenkonditionen </th>
                  </tr>
                  <tr>
                    <th>
                      <CheckIcon />
                    </th>
                    <th> Flexible Rentenhöhe bis 5.500 Euro </th>
                  </tr>
                  <tr>
                    <th>
                      <CheckIcon />
                    </th>
                    <th> Anpassung der Rentenhöhe ohne erneute Gesundheitsprüfung </th>
                  </tr>
                  <tr>
                    <th>
                      <CheckIcon />
                    </th>
                    <th> Jährliche Anpassung der Rente mit Hilfe einer Dynamik </th>
                  </tr>
                  <tr>
                    <th>
                      <CheckIcon />
                    </th>
                    <th> Anpassung der Rente während der Leistungsphase vereinbar </th>
                  </tr>
                  <tr>
                    <th>
                      <CheckIcon />
                    </th>
                    <th> Absicherung von Pflegebedürftigkeit über die Vertragslaufzeit hinaus </th>
                  </tr>
                  <tr>
                    <th>
                      <CheckIcon />
                    </th>
                    <th> Schutz weltweit und rund um die Uhr </th>
                  </tr>
                  <tr>
                    <th>
                      <CheckIcon />
                    </th>
                    <th> Keine abstrakte Verweisung: Sie werden nicht gezwungen eine andere Tätigkeit aufzunehmen </th>
                  </tr>
                  <tr>
                    <th>
                      <CheckIcon />
                    </th>
                    <th> Verzicht auf Beitragsanpassungen </th>
                  </tr>
                  <tr>
                    <th>
                      <CheckIcon />
                    </th>
                    <th>
                      Besserstellungsgarantie: Wenn Sie in einen risikoärmeren Beruf wechseln, senken wir Ihren Beitrag
                    </th>
                  </tr>
                  <tr>
                    <th>
                      <CheckIcon />
                    </th>
                    <th> Freie Arztwahl </th>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        )
      case 'vital protection flex':
        return (
          <div className="box-content">
            <div className="logo">
              <VitalProtectionFlexIcon />
            </div>
            <div className="product-title">
              Vitalschutz Flex ist die Grundfähigkeitsabsicherung für alle Beschäftigten in den der IG BCE
              angeschlossenen Branchen und deren Familienangehörigen.
            </div>
            <div className="product-text">
              <table>
                <tbody>
                  <tr>
                    <th>
                      <CheckIcon />
                    </th>
                    <th> Volle Leistung bei Verlust einer Grundfähigkeit </th>
                  </tr>
                  <tr>
                    <th>
                      <CheckIcon />
                    </th>
                    <th> Weltweiter Versicherungsschutz </th>
                  </tr>
                  <tr>
                    <th>
                      <CheckIcon />
                    </th>
                    <th> Günstiger Beitrag zu Großkundenkonditionen </th>
                  </tr>
                  <tr>
                    <th>
                      <CheckIcon />
                    </th>
                    <th> Individuelle Rentenhöhe </th>
                  </tr>
                  <tr>
                    <th>
                      <CheckIcon />
                    </th>
                    <th> Keine Fragen zu psychischen Vorerkrankungen bei Vitalschutz Flex M </th>
                  </tr>
                  <tr>
                    <th>
                      <CheckIcon />
                    </th>
                    <th> Nachträgliche Anpassung der abgesicherten Rente mit Hilfe einer Dynamik </th>
                  </tr>
                  <tr>
                    <th>
                      <CheckIcon />
                    </th>
                    <th> Anpassung ohne erneute Gesundheitsprüfung mit Hilfe der Nachversicherungsgarantie </th>
                  </tr>
                  <tr>
                    <th>
                      <CheckIcon />
                    </th>
                    <th>
                      Teilkapitalisierungsmöglichkeit: Bei Verlust bestimmter Grundfähigkeiten wird ein Kapital von bis
                      zur 12-fachen Monatsrente auf Wunsch ausgezahlt
                    </th>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        )
      case 'employment-protection':
        return (
          <div className="box-content">
            <div className="product-title">
              Arbeitskraftschutz Flex ist die Berufsunfähigkeitsabsicherung für die Beschäftigten der IG BCE
              angeschlossenen Branchen und deren Familienangehörigen.
            </div>
            <p>
              Arbeitskraftschutz Flex IG BCE bietet über 2 Millionen Beschäftigen der angeschlossenen Branchen inklusive
              deren Familienangehörigen mit BU Flex und Vitalschutz Flex die Möglichkeit, sich vor den finanziellen
              Folgen des Arbeitskraftverlustes zu schützen.
            </p>
            <br />
            <p>
              Die Vorsorgelösungen werden aus einem Zusammenschluss von drei Versicherern zur Verfügung gestellt: Swiss
              Life, R+V und Allianz stehen gemeinsam für die Leistungen ein und können somit besonders gute Konditionen
              anbieten. Alle drei Versicherer gehören zu den finanzstärksten Versicherern in Deutschland und stehen für
              Stabilität und Sicherheit.
            </p>
            <div className="insurance-logos">
              <div>
                <img src={SwissLifeImage} alt="swisslife" />
              </div>
              <div>
                <img src={RuVImage} alt="r+v" />
              </div>
              <div className="allianz-image">
                <img src={AllianzImage} alt="allianz" />
              </div>
            </div>
          </div>
        )
    }
  }

  render() {
    return <div id="product">{this.renderBoxContent(this.props.product)}</div>
  }
}

LogoutBox.propTypes = {
  product: PropTypes.object.isRequired
}

export default LogoutBox
