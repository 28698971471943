import React from 'react'
import { PropTypes } from 'prop-types'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import { connect } from 'react-redux'
import { setLocale, setRootDomain } from 'bundles/shared/lib/redux/actions'
import Footer from 'bundles/shared/components/footer'
import { withTranslation } from 'react-i18next'
import Login from './signed_off/login'
import Register from './signed_off/register'
import Header from './header'
import { StartImage } from './start_image'
import WelcomeText from './signed_off/welcome_text'
import ResetPassword from './signed_off/reset_password'
import EmailVerification from './signed_off/email_verification'
import EmailChangeVerification from './signed_off/email_change_verification'
import Boxes from './signed_off/boxes'
import LogoutText from './signed_off/logout_text'
import { LegalNotice } from './legal_notice'
import { PrivacyPolicyAdmin } from './privacy_policy_admin'
import { PrivacyPolicy } from './privacy_policy'
import CookieBanner from './cookie_banner'

const mapStateToProps = (state) => ({
  cookieConsentConfirmed: state.cookieConsentConfirmed,
  defaultLocale: state.locale,
  overlay: state.options.dialogOverlay
})

function mapDispatchToProps(dispatch) {
  return {
    setLocale: (locale) => dispatch(setLocale(locale)),
    setRootDomain: (domain) => dispatch(setRootDomain(domain))
  }
}

class Routes extends React.Component {
  static propTypes = {
    cookieConsentConfirmed: PropTypes.bool.isRequired,
    locale: PropTypes.string.isRequired,
    defaultLocale: PropTypes.string.isRequired,
    root_domain: PropTypes.string.isRequired,
    overlay: PropTypes.bool.isRequired,
    setLocale: PropTypes.func.isRequired,
    setRootDomain: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired
  }

  constructor(props) {
    super(props)
    if (this.props.locale !== this.props.defaultLocale) {
      this.props.setLocale(this.props.locale)
    }
    this.props.setRootDomain(this.props.root_domain)
    this.buildLegalPath()
  }

  buildLegalPath = () => {
    if (window.location.pathname.startsWith('/verwaltung')) {
      this.imprint = '/verwaltung/impressum'
      this.privacyPolicy = '/verwaltung/datenschutz'
    } else {
      this.imprint = '/impressum'
      this.privacyPolicy = '/datenschutz'
    }
  }

  render() {
    return (
      <Router basename="/">
        <div id="bu-flex">
          <Route path="/" component={Header} />
          <Route
            path="/"
            render={(props) =>
              !this.props.cookieConsentConfirmed && <CookieBanner {...props} privacyPolicy={this.privacyPolicy} />
            }
          />
          <div className="body">
            <Route path="/" component={StartImage} />
            <Route
              path="/(|login|register|verification/email_verification|verification/reset_password)"
              component={WelcomeText}
            />
            <div className="content">
              {this.props.overlay && <div id="overlay" />}
              <Route
                path="/(|login|register|verification/email_verification|verification/email_change_verification|verification/reset_password)"
                component={Boxes}
              />
              <Route exact path="/logout" component={LogoutText} />
              <Route exact path="/(|login|logout)" component={Login} />
              <Route exact path="/register" component={Register} />
              <Route exact path="/verification/reset_password/:id" component={ResetPassword} />
              <Route exact path="/verification/email_verification/:id" component={EmailVerification} />
              <Route exact path="/verification/email_change_verification/:id" component={EmailChangeVerification} />
              <Route exact path="/(impressum|verwaltung/impressum)" component={LegalNotice} />
              <Route exact path="/verwaltung/datenschutz" component={PrivacyPolicyAdmin} />
              <Route exact path="/datenschutz" component={PrivacyPolicy} />
            </div>
            <Route
              path="/"
              render={(props) => (
                <Footer
                  {...props}
                  isSignedIn={false}
                  imprint={this.imprint}
                  privacyPolicy={this.privacyPolicy}
                  privacyPolicyLinkText={this.props.t('bu:mainpage.footer.privacy_policy')}
                />
              )}
            />
          </div>
        </div>
      </Router>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Routes))
