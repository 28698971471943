import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ReactSelect from 'react-select'

class BuFlexSelect extends Component {
  static propTypes = {
    isDisabled: PropTypes.bool,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    options: PropTypes.array.isRequired,
    selected: PropTypes.string,
    cached_key: PropTypes.string,
    validationError: PropTypes.string
  }

  constructor(props) {
    super(props)
    const selectedOption = this.props.options.find((option) => option.value === this.props.selected)
    this.state = {
      selected: selectedOption
    }
  }

  errorMessage = () => !!this.props.validationError

  onChange = (selected) => {
    this.setState({ selected })
    this.props.onChange(selected.value)
  }

  render() {
    return (
      <>
        <label key={this.props.cached_key} className={this.props.validationError ? 'error' : null}>
          {this.props.label}
        </label>
        <ReactSelect
          {...this.props}
          classNamePrefix="react-select"
          value={this.state.selected}
          onChange={this.onChange}
          className={this.errorMessage() ? 'error select' : 'select'}
        />
      </>
    )
  }
}

export default BuFlexSelect
