import React from 'react'
import { PropTypes } from 'prop-types'
import { withRouter } from 'react-router-dom'
import { resetPassword } from 'bundles/shared/lib/requests'
import Input from 'bundles/shared/components/form/input'
import { withTranslation } from 'react-i18next'
import { compose } from 'myapos_recompose'

class PasswordResetForm extends React.Component {
  static propTypes = {
    onBackToLoginClick: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired
  }

  constructor(props) {
    super(props)
    this.state = {
      email: '',
      message: ''
    }
  }

  handleSubmit = (event) => {
    event.preventDefault()
    resetPassword(this.state.email, this.onSubmitSuccess)
  }

  onSubmitSuccess = (data) => {
    this.setState({ message: data.message })
  }

  onChange = (value) => {
    this.setState({ email: value })
  }

  render() {
    const t = this.props.t
    return (
      <div className="login-box">
        <br />
        {this.state.message ? (
          <div className="info-text">{this.state.message}</div>
        ) : (
          <form onSubmit={this.handleSubmit}>
            <Input
              validationErrors={{}}
              onChange={this.onChange}
              key="reset_password"
              labelPrefix="session"
              attr="password_reset_email"
            />
            <input type="submit" value={t('bu:buttons.reset_password')} className="btn" />
          </form>
        )}
        <div>
          <a onClick={() => this.props.onBackToLoginClick()}>{t('session.back_to_login_link')}</a>
        </div>
      </div>
    )
  }
}

export default compose(withRouter, withTranslation())(PasswordResetForm)
