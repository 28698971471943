import React from 'react'
import PropTypes from 'prop-types'
import CloseIcon from 'icons/shared/close.svg'

const InfoDialog = ({ infoTitle, handleCancel }) => (
  <div className="info-dialog">
    <CloseIcon onClick={handleCancel} className="close" />
    <div className="info-title">{infoTitle}</div>
    <div className="info-text">
      <p>
        Ich stimme hiermit zu, dass die nachfolgenden personenbezogenen Daten im Rahmen des Registrierungsprozesses für
        das Portal Arbeitskraftschutz Flex (AKS Flex) gemäß Art. 4 Nr. 2 DSGVO erhoben bzw. verarbeitet werden:
      </p>
      <ul>
        <li>Vor- und Zuname,</li>
        <li>E-Mail Adresse,</li>
        <li>Zugehörigkeit zu einer Vertriebsorganisation,</li>
        <li>Vermittlungsnummer (optionale Angabe),</li>
        <li>Passwort</li>
        <li>Telefonnummer (optional Angabe über die Profilverwaltung).</li>
        <li>Firmenname (optional über Profilverwaltung)</li>
        <li>Vermittlerregisternummer (optional über Profilverwaltung)</li>
        <li>Anschrift (optional über Profilverwaltung)</li>
      </ul>
      <p>
        Eine Erhebung bzw. Verarbeitung dieser Daten ist für eine Identifikation meiner Person bzw. der Ermittlung einer
        Zugangsberechtigung für die Nutzung des Portals AKS Flex erforderlich.
      </p>
      <p>
        Zudem erfolgt eine Verwendung dieser Daten zum Zweck der Erstellung einer internen Datenbank, um eine
        zutreffende Ermittlung einer Zugangsberechtigung für das Portal durchführen zu können.
      </p>
      <p>
        Eine Speicherung der Daten erfolgt mindestens für den tatsächlichen Verwendungszeitraum des Portals AKS Flex,
        darüber hinaus jedoch längstens für einen Zeitraum von einem Jahr. Eine Weitergabe der Daten an ein Drittland
        außerhalb der Europäischen Union findet nicht statt; die Verarbeitung der personenbezogenen Daten erfolgt
        ausschließlich in Deutschland.
      </p>
      <p>
        Verantwortliche Stelle für die Verarbeitung der hier genannten Daten gemäß Art. 4 Nr. 7 DSGVO ist die IG BCE
        Bonusagentur GmbH.
      </p>
      <p>
        Weitere Informationen bezüglich des Umgangs mit Ihren Daten, eine Liste unserer externen Auftragsverarbeiter
        sowie den Ihnen zustehenden Rechten entnehmen Sie bitte unserer Datenschutzerklärung (
        <a href="https://igbce-aksflex.de/datenschutz">www.igbce-aksflex.de/datenschutz</a>).
      </p>
      <p>
        Sie haben jederzeit das Recht, Ihre hiermit erteilte Einwilligung zu widerrufen. Die Rechtmäßigkeit der
        Datenverarbeitung bleibt bis zum Zeitpunkt des Widerrufs unberührt. Hierfür senden Sie bitte eine E-Mail mit dem
        Stichwort “Widerruf” an die nachfolgende E-Mail Adresse:{' '}
        <a href="mailto:kontakt@igbce-bonusagentur.de">kontakt@igbce-bonusagentur.de</a>
      </p>
    </div>
  </div>
)

InfoDialog.propTypes = {
  handleCancel: PropTypes.func.isRequired,
  infoTitle: PropTypes.string.isRequired
}

export default InfoDialog
