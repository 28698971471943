import { createStore } from 'redux'
import Cookies from 'universal-cookie'
import { reducer } from './reducer'

const cookies = new Cookies()

const storedContractualCustomers =
  (localStorage.getItem('contractualCustomers') && JSON.parse(localStorage.getItem('contractualCustomers'))) || {}
const storedPotentialCustomers =
  (localStorage.getItem('potentialCustomers') && JSON.parse(localStorage.getItem('potentialCustomers'))) || {}

const initialState = {
  apps: null,
  cookieConsentConfirmed: cookies.get('cookieConsentConfirmed') === 'true',
  flashMessage: null,
  flashType: null,
  locale: 'de',
  sideNavIsShown: false,
  theme: 'xion',
  rootDomain: 'localhost',
  currentUser: {},
  options: {
    dialogOverlay: false,
    pathName: window.location.pathname
  },
  xCRM: {
    billingAddresses: [],
    contractualCustomers: storedContractualCustomers,
    contractualCustomersFilter: '',
    customer: {},
    employees: [],
    employeesDup: [],
    employeeFilterId: null,
    employeeFilterType: 'all',
    employeeKeywords: [],
    potentialCustomers: storedPotentialCustomers,
    potentialCustomersFilter: '',
    shippingAddresses: [],
    SubNavItems: {
      potential_customers: { route: '/xcrm/potential_customers' },
      contractual_customers: { route: '/xcrm/contractual_customers' }
      // contacts: {route: '/xcrm/contacts', active: false}
    }
  },

  xDNS: {
    SubNavItems: {
      zones: { route: '/zones' },
      ptr_records: { route: '#' }
    }
  }
}

export const store = createStore(reducer, initialState)
