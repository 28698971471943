import React from 'react'
import { PropTypes } from 'prop-types'
import { withTranslation } from 'react-i18next'

const LogoutText = ({ t }) => (
  <div id="logout-box">
    <p className="title">{t('bu:logout.title')}</p>
    <p className="sub-title">{t('bu:logout.text_1')}</p>
    <br />
    <p className="logout-link-text">
      {t('bu:logout.text_2')}
      &nbsp;
      <a href="/">{t('bu:logout.link_text')}</a>.
    </p>
  </div>
)

LogoutText.propTypes = {
  t: PropTypes.func.isRequired
}

export default withTranslation()(LogoutText)
